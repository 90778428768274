"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadSynchronous = exports.load = void 0;
var DomReady_1 = require("./DomReady");
var I18n_1 = require("./I18n");
var TeaserSlider_1 = require("./TeaserSlider");
var Carousel_1 = require("./Carousel");
var Expandable_1 = require("./Expandable");
var ExpandableTrafficRss_1 = require("./ExpandableTrafficRss");
var ExpandableConstruction_1 = require("./ExpandableConstruction");
var SearchPageDynamic_1 = require("./SearchPageDynamic");
var PanoramaImage_1 = require("./PanoramaImage");
var PanoramaImageOverlay_1 = require("./PanoramaImageOverlay");
var PanoramaScenesService_1 = require("./PanoramaScenesService");
var PanoramaCollection_1 = require("./PanoramaCollection");
var IframeOverlay_1 = require("./IframeOverlay");
var TrainEquipment_1 = require("./TrainEquipment");
var Him_1 = require("./Him");
var Co2_1 = require("./Co2");
var ContrastSwitch_1 = require("./ContrastSwitch");
var QueryStringHandler_1 = require("./QueryStringHandler");
var Quiz_1 = require("./Quiz");
var chat_lcw_1 = require("./chat-lcw");
var RowEqualizer_1 = require("./RowEqualizer");
var utils_1 = require("./utils");
var animated_scroll_to_1 = require("animated-scroll-to");
var Video_1 = require("./Video");
var Angebotssuche_1 = require("./Angebotssuche");
var ScrollTo_1 = require("./ScrollTo");
var IframeListener_1 = require("./IframeListener");
var Waterfall_1 = require("./Waterfall");
var Typing_1 = require("./Typing");
var PiwikTracking_1 = require("./PiwikTracking");
var KlimaticketScopeMap_1 = require("./KlimaticketScopeMap");
var Redirect_1 = require("./Redirect");
var TeaserSliderGeo_1 = require("./TeaserSliderGeo");
var GeolocationService_1 = require("./GeolocationService");
var VideoWaterfallDisclaimer_1 = require("./VideoWaterfallDisclaimer");
(0, utils_1.checkJS)();
var selectorForStencil = [
    '.js-loyalty-program',
    'loyalty-program-main-screen',
    'loyalty-program-login',
    'loyalty-program-username',
    'oebbx-app-from-to',
    'oebbx-app-station-info',
    'oebbx-app-vorteilscard-calculator',
    'oebbx-delay-confirmation',
    'oebbx-app-luggage-calculator',
    'oebbx-app-connections',
    'oebbx-app-val-stops',
    'oebbx-val-stop',
    'oebbx-val-stops-map',
    'consent-manager',
    'postbus-timetables',
    'rail-and-drive-locations-map',
].join(',');
var selectorForLegacyWebComponents = [
    'oebb-fm-app-form',
    'oebb-fm-form',
    'oebb-date-field',
    'oebb-delay-confirmation',
].join(',');
function loadWebComponents(stencil, legacy) {
    if (stencil === void 0) { stencil = true; }
    if (legacy === void 0) { legacy = true; }
    if (stencil && document.querySelector(selectorForStencil)) {
        // Load the pv-2017 stencil components (including oebb-web-components)
        (0, utils_1.loadScript)((0, utils_1.getResourceUrl)('stencil/pv-2017/pv-2017.esm.js'), 'pv-2017-module', null, 'module');
    }
    if (legacy && document.querySelector(selectorForLegacyWebComponents)) {
        (0, utils_1.loadScript)((0, utils_1.getResourceUrl)('js/oebb-web-components.js'));
    }
}
(0, DomReady_1.domReady)(function (event) {
    (0, utils_1.lazyLoadImages)();
    document.addEventListener('mousedown', function (e) {
        document.documentElement.classList.remove('is-tabbing');
    });
    document.addEventListener('keydown', function (e) {
        if (e.keyCode !== 9)
            return;
        document.documentElement.classList.add('is-tabbing');
    });
    document.addEventListener('keyup', function (event) {
        if (event.keyCode === 27)
            closeMobileMenu();
    });
    var currentLang = retrieveCurrentLanguage();
    var i18n = new I18n_1.I18n(currentLang);
    // Remove designed margin in ".col-4" if ".col-8" has heading
    var columns = document.querySelectorAll('.col-8');
    for (var i_1 = 0; i_1 < columns.length; i_1++) {
        var col = columns[i_1];
        if (col.firstElementChild.nodeName.toLowerCase().indexOf('h', 0) !== 0) {
            col.classList.add('col-8--without-heading');
        }
    }
    if (document.querySelector('.js-frame-autoheight')) {
        var frames_1 = document.querySelectorAll('.js-frame-autoheight');
        for (var i = 0; i < frames_1.length; i++) {
            new IframeListener_1.IframeListener(frames_1.item(i));
        }
    }
    if (document.querySelector('.js-waterfall__sequenz')) {
        new Waterfall_1.Waterfall(document.querySelectorAll('.js-waterfall__sequenz'), 'oebb-yt-videos');
        var youtubeVideos = document.querySelectorAll(".waterfall__background--video");
        if (youtubeVideos) {
            new VideoWaterfallDisclaimer_1.VideoWaterfallDisclaimer(youtubeVideos, currentLang);
        }
    }
    if (document.querySelector('.js-typing')) {
        for (var i = 0; i < document.querySelectorAll('.js-typing').length; i++) {
            new Typing_1.Typing(document.querySelectorAll('.js-typing').item(i));
        }
    }
    if (document.querySelector('.js-row-equalized')) {
        new RowEqualizer_1.RowEqualizer(document.querySelectorAll('.js-row-equalized'));
    }
    if (document.querySelector('.js-scroll-to')) {
        for (var i = 0; i < document.querySelectorAll('.js-scroll-to').length; i++) {
            new ScrollTo_1.ScrollTo(document.querySelectorAll('.js-scroll-to').item(i));
        }
    }
    if ("ontouchstart" in document.documentElement) {
        document.querySelector("html").classList.add("touch");
    }
    else {
        document.querySelector("html").classList.add("no-touch");
    }
    if (document.querySelector(".train-equipment-scrollpanel")) {
        new TrainEquipment_1.TrainEquipment();
    }
    var menuOpen = false;
    document.ontouchmove = function (event) {
        if (menuOpen) {
            var navElement = document.querySelector("ul.main-nav");
            var currentTarget = event.target;
            if (navElement.scrollHeight == navElement.parentElement.offsetHeight || currentTarget.classList.contains("open") || currentTarget.classList.contains("main-nav")) {
                event.preventDefault();
                event.stopPropagation();
            }
            else {
                event.stopPropagation();
            }
        }
    };
    var linksRoleButton = document.querySelectorAll('[role="button"]');
    var _loop_1 = function (i_2) {
        linksRoleButton[i_2].addEventListener('keydown', function (e) {
            if (linksRoleButton[i_2].classList.contains('js-show-nav-sub-panel') || linksRoleButton[i_2].classList.contains('js-nav-item-toggle'))
                return;
            if (e.keyCode === 32) {
                e.preventDefault();
                linksRoleButton[i_2].click();
            }
        }, false);
    };
    for (var i_2 = 0; i_2 < linksRoleButton.length; i_2++) {
        _loop_1(i_2);
    }
    var queryStringHandler = new QueryStringHandler_1.QueryStringHandler(document.querySelectorAll('[href^="https://shop.oebbtickets.at/"], [href^="https://tickets.postbus.at/"]'));
    var tsQueryParameters = ['cref', 'pk_campaign', 'pk_kwd', 'pk_source', 'pk_medium', 'pk_content'];
    var tsQueryParts = [];
    var tsQueryString;
    tsQueryParameters.forEach(function (tsQueryParameter) {
        var value = queryStringHandler.handle(tsQueryParameter);
        if (value)
            tsQueryParts.push("".concat(tsQueryParameter, "=").concat(value));
    });
    tsQueryString = tsQueryParts.join('&');
    if (tsQueryString && document.querySelector('.js-from-to-form')) {
        document.querySelector('.js-from-to-form').setAttribute('ts-query-string', tsQueryString);
    }
    if (document.querySelector('.js-contrast-switch')) {
        new ContrastSwitch_1.ContrastSwitch(document.querySelector('.js-contrast-switch'), function () {
            if (document.querySelector('oebb-from-to-form')) {
                var fromToForm = document.querySelector('oebb-from-to-form');
                fromToForm.classList.toggle('contrast');
            }
        });
    }
    if (document.querySelector('.js-quiz')) {
        new Quiz_1.Quiz(document.querySelector('.js-quiz'), i18n);
    }
    if (document.querySelector('.js-panorama-image')) {
        PanoramaImage_1.PanoramaImage.addStyleAndScript(function () {
            new PanoramaImage_1.PanoramaImage(pannellum, document.querySelector('.js-panorama-image'), currentLang);
        });
    }
    if (document.querySelector('.js-panorama-image-overlay')) {
        var panoramaImages_1 = document.querySelectorAll('.js-panorama-image-overlay');
        PanoramaImage_1.PanoramaImage.addStyleAndScript(function () {
            var scenes = new PanoramaScenesService_1.PanoramaScenesService(panoramaImages_1).getData();
            new PanoramaImageOverlay_1.PanoramaImageOverlay(pannellum, panoramaImages_1, scenes, currentLang);
        });
    }
    // @todo Test in Testumgebung sowie Print Ansicht und Train Equipment wegen "roundme-link"
    if (document.querySelector('.js-panorama-collection')) {
        new PanoramaCollection_1.PanoramaCollection(document.querySelector('.js-panorama-collection'));
    }
    var videos = (0, Video_1.initVideo)(currentLang);
    (0, IframeOverlay_1.initIframeOverlay)(currentLang, videos);
    if (document.querySelector('.js-video-internal')) {
        var videoModules = document.querySelectorAll('.js-video-internal');
        videoModules.forEach(function (module) {
            var button = module.querySelector('.js-video-internal-play');
            var video = module.querySelector('video');
            var isVideoPlaying = !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
            video.controls = false;
            button.hidden = false;
            button.setAttribute('aria-pressed', isVideoPlaying ? 'true' : 'false');
            button.addEventListener('click', function () {
                if (video.paused) {
                    video.play();
                    button.setAttribute('aria-pressed', 'true');
                }
                else {
                    video.pause();
                    button.setAttribute('aria-pressed', 'false');
                }
            });
        });
    }
    if (document.querySelector("#search-page-dynamic")) {
        new SearchPageDynamic_1.SearchPageDynamic(currentLang);
    }
    if (document.querySelector(".js-him")) {
        new Him_1.Him(document.querySelector(".js-him"));
    }
    if (document.querySelector(".js-angebotssuche")) {
        new Angebotssuche_1.Angebotssuche(document.querySelector(".js-angebotssuche"));
    }
    if (document.querySelector('.js-chat-lcw-start')) {
        (0, chat_lcw_1.initChat)();
    }
    if (document.querySelector('.js-klimaticket-scope-map')) {
        new KlimaticketScopeMap_1.KlimaticketScopeMap(document.querySelector('.js-klimaticket-scope-map'));
    }
    new PiwikTracking_1.PiwikTracking();
    if (document.querySelector('.js-redirect-details')) {
        var redirectDetails = document.querySelector('.js-redirect-details');
        Redirect_1.Redirect.getInstance().go(redirectDetails.dataset.url, Number(redirectDetails.dataset.delay) * 1000);
    }
    var co2Counters = document.querySelectorAll('.js-co2-count');
    for (var i_3 = 0; i_3 < co2Counters.length; i_3++) {
        new Co2_1.Co2(co2Counters[i_3]);
    }
    var burgerLink = document.getElementById("burger-link");
    var mainMenu = document.getElementById("main-menu");
    var mainNavCloseLink = document.querySelector(".main-nav-close-link");
    var html = document.querySelector("html");
    var header = document.querySelector("header");
    var teaserSliders = document.querySelectorAll(".teaser-slider");
    var teaserSliderInstances = [];
    for (var i = 0; i < teaserSliders.length; i++) {
        var teaserSlider = new TeaserSlider_1.TeaserSlider(teaserSliders.item(i));
        teaserSliderInstances.push(teaserSlider);
    }
    if (!document.documentElement.classList.contains('js-edit-mode')) {
        teaserSliderInstances.forEach(function (currentSliderInstance) {
            var currentSliderElement = currentSliderInstance.getElement();
            GeolocationService_1.GeolocationService.url = currentSliderElement.dataset.url + '/geolocation';
            if (currentSliderElement.classList.contains('js-teaser-slider-generic-geo')) {
                new TeaserSliderGeo_1.TeaserSliderGeo(currentSliderInstance, GeolocationService_1.GeolocationService.getInstance());
            }
        });
    }
    else {
        teaserSliderInstances.forEach(function (currentSliderInstance) {
            currentSliderInstance.getElement().hidden = false;
        });
    }
    var carousels = document.querySelectorAll(':not(.edit-mode) .js-carousel');
    for (var i_4 = 0; i_4 < carousels.length; i_4++) {
        new Carousel_1.Carousel(carousels[i_4]);
    }
    var fromToModule = document.querySelector('.js-from-to');
    var firstSlider = document.querySelector('.js-image-title-slider');
    var hoveredFromTo = false;
    if (fromToModule && firstSlider) {
        fromToModule.addEventListener('mouseover', function (e) {
            if (hoveredFromTo)
                return;
            hoveredFromTo = true;
            firstSlider.ref.stop();
        });
    }
    var expandables = document.querySelectorAll('.js-expandable');
    for (var i_5 = 0; i_5 < expandables.length; i_5++) {
        new Expandable_1.Expandable(expandables[i_5]);
    }
    var expandablesTrafficElements = document.querySelectorAll('.js-expandable-traffic-rss, .js-expandable-traffic-filter-rss');
    if (expandablesTrafficElements.length) {
        initExpandableElements();
    }
    function initExpandableElements() {
        for (var i_6 = 0; i_6 < expandablesTrafficElements.length; i_6++) {
            var useHafas = expandablesTrafficElements[i_6].getAttribute('data-source') == 'hafas';
            if (useHafas) {
                var region = expandablesTrafficElements[i_6].getAttribute('data-region');
                new ExpandableConstruction_1.ExpandableConstruction(expandablesTrafficElements[i_6], i18n, region).loadItemsAndRender();
            }
            else {
                initExpandableTrafficRssElements(i_6);
            }
        }
    }
    function initExpandableTrafficRssElements(i) {
        var filterKeywords = getFilterKeyWords(i);
        var filterMethod = getFilterMethod(i);
        new ExpandableTrafficRss_1.ExpandableTrafficRss(expandablesTrafficElements[i], i18n, filterMethod, filterKeywords).loadItemsAndRender();
    }
    function getFilterKeyWords(i) {
        return expandablesTrafficElements[i].dataset.filterKeywords ? expandablesTrafficElements[i].dataset.filterKeywords.split(',') : [''];
    }
    function getFilterMethod(i) {
        var _a;
        var filterMethodFromElement = (_a = expandablesTrafficElements[i].dataset.filterMethod) !== null && _a !== void 0 ? _a : "IN";
        switch (filterMethodFromElement) {
            case 'NOT':
                return ExpandableTrafficRss_1.TrafficRssFilterMethod.NOT;
            default:
                return ExpandableTrafficRss_1.TrafficRssFilterMethod.IN;
        }
    }
    var tarifeKlappboxen = document.querySelectorAll('.tarifeKlappbox');
    for (var i_7 = 0; i_7 < tarifeKlappboxen.length; i_7++) {
        new Expandable_1.Expandable(tarifeKlappboxen[i_7]);
        var links = tarifeKlappboxen[i_7].querySelectorAll('a');
        for (var i_8 = 0; i_8 < links.length; i_8++) {
            links[i_8].addEventListener('click', function (e) {
                if (!this.classList.contains('js-klappbox-anchor') // not a expandable klappbox
                    && this.href.indexOf('static/tarife') > -1 // but an internal link of the document
                    && this.href.indexOf('#') > -1 // with an hash
                ) {
                    e.preventDefault();
                    scrollToTarifeHash(this.href.split('#')[1]);
                }
            });
        }
    }
    if (document.querySelector('#tarife') && window.location.hash) {
        setTimeout(function () { scrollToTarifeHash(window.location.hash.substr(1), true); }, 500);
        var langLinks = document.querySelectorAll('.js-lang-nav a');
        for (var i_9 = 0; i_9 < langLinks.length; i_9++) {
            langLinks[i_9].href = langLinks[i_9].href + window.location.hash;
        }
    }
    function scrollToTarifeHash(hash, animate) {
        if (animate === void 0) { animate = true; }
        loadClosestPolyfill();
        var element = document.querySelector("[id=\"".concat(hash, "\"]"));
        while (element) {
            var currentElement = element.closest('.tarifeKlappbox');
            if (!element.parentNode.classList.contains('tarife')) {
                if (currentElement) {
                    currentElement.ref.setOpen();
                    element = currentElement.parentNode;
                }
                else {
                    var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                    var rect = document.querySelector("[id=\"".concat(hash, "\"]")).getBoundingClientRect();
                    if (animate) {
                        (0, animated_scroll_to_1.default)(scrollTop + rect.top - 80);
                    }
                    else {
                        window.scrollTo(0, rect.top + scrollTop - 80);
                    }
                    break;
                }
            }
            else {
                break;
            }
        }
    }
    if (document.querySelector('.js-skip-to-menu')) {
        document.querySelector('.js-skip-to-menu').addEventListener('click', function (e) {
            if (isMobileViewport()) {
                e.preventDefault();
                burgerLink.focus();
            }
        });
    }
    if (document.querySelector('.js-jump-over-from-to')) {
        document.querySelector('.js-jump-over-from-to').addEventListener('click', function (e) {
            e.preventDefault();
            // Make skiplink for from-to form more robust
            var previousSibling = document.querySelector('.him-from-to');
            var linkToFocus;
            do {
                linkToFocus = previousSibling.nextElementSibling.querySelector('a');
                previousSibling = previousSibling.nextElementSibling;
            } while (linkToFocus == null);
            linkToFocus.focus();
        });
    }
    if (mainMenu) {
        mainMenu.addEventListener("click", function (event) {
            var target = event.target;
            // console.log(target)
            if (target.classList && target.classList.contains("main-menu")) {
                event.preventDefault();
                event.stopPropagation();
                mainMenu
                    .classList
                    .toggle("open");
                html.classList
                    .toggle("ocopen");
                menuOpen = !menuOpen;
                deactivateNavSubPanel();
            }
        }, false);
    }
    if (mainNavCloseLink && mainMenu) {
        mainNavCloseLink.addEventListener("click", function (event) {
            event.preventDefault();
            mainMenu
                .classList
                .toggle("open");
            html.classList
                .toggle("ocopen");
            menuOpen = !menuOpen;
            deactivateNavSubPanel();
        }, false);
    }
    function closeMobileMenu() {
        if (!menuOpen)
            return;
        if (mainMenu.classList.contains('has-active-sub-panel')) {
            deactivateNavSubPanel();
        }
        else {
            mainMenu.classList.remove("open");
            html.classList.toggle("ocopen");
            menuOpen = false;
        }
    }
    if (burgerLink) {
        burgerLink.addEventListener("click", function (event) {
            event.preventDefault();
            mainMenu
                .classList
                .toggle("open");
            html.classList
                .toggle("ocopen");
            menuOpen = !menuOpen;
            // console.log("menuOpen: "+menuOpen)
        }, false);
        burgerLink.addEventListener('focus', function () {
            if (html.classList.contains('ocopen')) {
                closeMobileMenu();
            }
        });
    }
    var mainNavPanel = document.querySelector('.js-main-nav-panel');
    if (mainNavPanel) {
        document.querySelector('.js-main-nav-panel').addEventListener('click', function (e) {
            if (!e.target.classList.contains('js-main-nav-panel'))
                return;
            if (document.documentElement.classList.contains('ocopen')) {
                closeMobileMenu();
            }
        });
    }
    var latestScrollY = 0;
    var ticking = false;
    window.addEventListener('scroll', function (e) {
        latestScrollY = getScrollTop();
        requestTick();
    }, { passive: true });
    function requestTick() {
        if (!ticking) {
            requestAnimationFrame(stickHeader);
        }
        ticking = true;
    }
    function stickHeader() {
        ticking = false;
        if (latestScrollY >= 75) {
            html.classList.add('header-is-sticky');
            header.classList.add('is-sticky');
        }
        else {
            html.classList.remove('header-is-sticky');
            header.classList.remove('is-sticky');
        }
    }
    var menuElements = document.querySelectorAll("ul.main-nav > li > a");
    var timeout;
    if (mainMenu) {
        mainMenu.addEventListener("mouseleave", function (e) {
            e.preventDefault();
            e.stopPropagation();
            if (isDesktopViewport()) {
                for (var iy = 0; iy < menuElements.length; iy++) {
                    menuElements[iy].parentElement.classList.remove("open");
                }
            }
        });
        var _loop_2 = function () {
            if (menuElements[i].nextElementSibling) {
            }
            menuElements[i].addEventListener("mouseover", function (e) {
                event.preventDefault();
                event.stopPropagation();
                var self = this;
                var menuItemTimeout = (this.classList.contains('js-nav-item-instant')) ? 0 : 200;
                if (isDesktopViewport()) {
                    clearTimeout(timeout);
                    timeout = setTimeout(function (e) {
                        for (var iy = 0; iy < menuElements.length; iy++) {
                            // if (menuElements[iy].nextElementSibling) {
                            menuElements[iy].parentElement.classList.remove("open");
                            // }
                        }
                        self.parentElement.classList.add("open");
                    }, menuItemTimeout);
                }
            });
            if (i > 0 && menuElements[i].parentElement.querySelector("div") && !menuElements[i].classList.contains('js-nav-item-instant') && !menuElements[i].classList.contains('js-nav-item-toggle')) {
                openLink = document.createElement("a");
                openLink.href = "#";
                openLink.innerHTML = "\n                    <span class=\"screen-reader-text\">".concat(i18n.getProperty('global.navigation.expand', 'Untermenü von'), " ").concat(menuElements[i].textContent.trim(), "</span>\n                    <span class=\"js-open-link-icon\" aria-hidden=\"true\">+</span>\n                ");
                openLink.classList.add("open-link");
                menuElements[i].parentElement.insertBefore(openLink, menuElements[i].parentElement.querySelector('div'));
                var toggleSubNav_1 = function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    var currentTarget = e.currentTarget;
                    currentTarget.parentElement.classList.toggle('open');
                    if (currentTarget.parentElement.classList.contains('open')) {
                        currentTarget.querySelector('.js-open-link-icon').textContent = '-';
                    }
                    else {
                        currentTarget.querySelector('.js-open-link-icon').textContent = '+';
                    }
                };
                openLink.addEventListener('click', function (e) {
                    toggleSubNav_1(e);
                });
                openLink.addEventListener('keydown', function (e) {
                    if (e.keyCode === 32)
                        toggleSubNav_1(e);
                });
            }
            if (menuElements[i].classList.contains('js-nav-item-toggle')) {
                var toggleDropdown_1 = function (e, element) {
                    e.preventDefault();
                    e.stopPropagation();
                    if (element.parentElement.classList.contains('open')) {
                        element.parentElement.classList.remove('open');
                    }
                    else {
                        element.parentElement.classList.add('open');
                    }
                };
                menuElements[i].addEventListener('click', function (e) {
                    toggleDropdown_1(e, this);
                });
                menuElements[i].addEventListener('keydown', function (e) {
                    if (e.keyCode === 32)
                        toggleDropdown_1(e, this);
                });
                var menuToggle_1 = menuElements[i];
                document.addEventListener('keydown', function (e) {
                    if (e.key === 'Esc' || e.key === 'Escape') {
                        menuToggle_1.parentElement.classList.remove('open');
                    }
                });
            }
            else {
                var lastAnchorElements = menuElements[i].parentElement.querySelectorAll('div > div > ul > li:last-child > a');
                if (lastAnchorElements.length) {
                    lastAnchorElements[lastAnchorElements.length - 1].addEventListener('keydown', function (e) {
                        if (e.keyCode !== 9)
                            return;
                        if (!isDesktopViewport())
                            return;
                        if (this.parentElement.classList.contains('has-active-sub-panel'))
                            return;
                        setTimeout(function () { document.querySelector('.main-nav > li.open').classList.remove('open'); }); // Needs timeout to set focus
                    });
                }
            }
            menuElements[i].addEventListener("mouseout", function (e) {
                event.preventDefault();
                event.stopPropagation();
                clearTimeout(timeout);
            });
            if (menuElements[i].nextSibling) {
                childNode = menuElements[i].nextSibling;
                childNode.addEventListener("mouseleave", function (event) {
                    event.preventDefault();
                    event.stopPropagation();
                    if (isDesktopViewport()) {
                        event.target.parentElement.classList.remove("open");
                    }
                });
            }
            if (menuElements[i].classList.contains('js-anchor-link')) {
                menuElements[i].addEventListener('click', function (e) {
                    if (menuOpen)
                        closeMobileMenu();
                });
            }
        };
        var openLink, childNode;
        for (var i = 0; i < menuElements.length; i++) {
            _loop_2();
        }
    }
    document.documentElement.classList.add('dom-ready');
    var allExternalLinks = document.querySelectorAll('a[target="_blank"]');
    var externalHint = i18n.getProperty('global.externalLink', 'Öffnet im neuen Fenster');
    for (var i_10 = 0; i_10 < allExternalLinks.length; i_10++) {
        var title = allExternalLinks[i_10].getAttribute('title');
        allExternalLinks[i_10].setAttribute('title', title ? "".concat(title, " - ").concat(externalHint) : externalHint);
    }
    var mainMenuElement = document.querySelector('.js-main-menu');
    var langNav = document.querySelector('.js-lang-nav');
    var navSubPanelTitle = document.createElement('span');
    var navSubPanelBtnBack = document.createElement('button');
    var navSubPanelBtnText = document.createElement('span');
    navSubPanelTitle.classList.add('nav-sub-panel-title');
    navSubPanelBtnBack.classList.add('nav-sub-panel-btn-back');
    navSubPanelBtnBack.classList.add('ic-nav-backward');
    navSubPanelBtnText.classList.add('screen-reader-text');
    navSubPanelBtnText.textContent = i18n.getProperty('global.navigation.subMenuClose', 'Untermenü schließen');
    navSubPanelBtnBack.appendChild(navSubPanelBtnText);
    if (document.querySelector('.service-nav')) {
        document.querySelector('.service-nav').addEventListener('focus', function () {
            if (menuOpen)
                closeMobileMenu();
        }, true);
    }
    else {
        document.querySelector('.scroll-container').addEventListener('focus', function () {
            if (menuOpen)
                closeMobileMenu();
        }, true);
    }
    var allMenuLinks = document.querySelectorAll('.main-nav > li > a, .main-nav > li > div > div > ul > li > a');
    for (var i_11 = 0; i_11 < allMenuLinks.length; i_11++) {
        allMenuLinks[i_11].addEventListener('focus', function (e) {
            if (mainMenuElement.classList.contains('has-active-sub-panel'))
                deactivateNavSubPanel();
        }, false);
    }
    if (document.querySelector('.js-show-nav-sub-panel')) {
        var navSubPanelBtns = document.querySelectorAll('.js-show-nav-sub-panel');
        for (var i_12 = 0; i_12 < navSubPanelBtns.length; i_12++) {
            navSubPanelBtns[i_12].addEventListener('click', function (e) {
                e.preventDefault();
                var parent = this.parentNode;
                toggleNavSubPanel(parent, this);
                return false;
            });
            navSubPanelBtns[i_12].addEventListener('keydown', function (e) {
                if (e.keyCode !== 32)
                    return;
                e.preventDefault();
                var parent = this.parentNode;
                toggleNavSubPanel(parent, this);
            });
            navSubPanelBtns[i_12].addEventListener('mouseleave', function () {
                if (isMobileViewport())
                    return;
                removeClassByName('has-active-sub-panel');
            });
        }
    }
    navSubPanelBtnBack.addEventListener('click', function (e) {
        e.preventDefault();
        deactivateNavSubPanel();
        return false;
    });
    function toggleNavSubPanel(parent, button, title) {
        if (parent.classList.contains('has-active-sub-panel')) {
            deactivateNavSubPanel();
        }
        else {
            activateNavSubPanel(parent, button);
        }
    }
    function activateNavSubPanel(parent, button, title) {
        parent.classList.add('has-active-sub-panel');
        parent.querySelector('ul li:first-child').addEventListener('keydown', function (e) {
            if (!(e.shiftKey && e.keyCode == 9))
                return;
            e.preventDefault();
            deactivateNavSubPanel();
            button.focus();
        });
        parent.querySelector('ul li:last-child').addEventListener('keydown', function (e) {
            if (e.keyCode !== 9 || (e.shiftKey && e.keyCode == 9))
                return;
            e.preventDefault();
            deactivateNavSubPanel();
            button.focus();
        });
        if (isDesktopViewport())
            return;
        if (!title)
            title = parent.querySelector('a').textContent.trim();
        navSubPanelTitle.textContent = title;
        mainMenu.classList.add('has-active-sub-panel');
        document.querySelector('.js-main-menu').appendChild(navSubPanelTitle);
        document.querySelector('.js-main-menu').appendChild(navSubPanelBtnBack);
        // Trigger layout to make animation work
        window.getComputedStyle(navSubPanelTitle).opacity;
        window.getComputedStyle(navSubPanelBtnBack).opacity;
        if (langNav)
            langNav.classList.add('lang-nav--slide-out');
        navSubPanelTitle.classList.add('nav-sub-panel-element-slide-in');
        navSubPanelBtnBack.classList.add('nav-sub-panel-element-slide-in');
    }
    function deactivateNavSubPanel() {
        mainMenu.classList.remove('has-active-sub-panel');
        removeClassByName('has-active-sub-panel');
        removeClassByName('nav-sub-panel-element-slide-in');
        if (langNav)
            langNav.classList.remove('lang-nav--slide-out');
        if (isDesktopViewport())
            return;
        setTimeout(function () {
            if (navSubPanelTitle.parentNode)
                navSubPanelTitle.parentNode.removeChild(navSubPanelTitle);
            if (navSubPanelBtnBack.parentNode)
                navSubPanelBtnBack.parentNode.removeChild(navSubPanelBtnBack);
        }, 500);
    }
    function removeClassByName(className) {
        Array.prototype.map.call(document.querySelectorAll(".".concat(className)), function (el) {
            el.classList.remove(className);
        });
    }
    function isMobileViewport() {
        if (window.innerWidth < 1240)
            return true;
        return false;
    }
    function isDesktopViewport() {
        if (window.innerWidth >= 1240)
            return true;
        return false;
    }
    if (document.querySelector('.js-onboard-consumer')) {
        var onboardConsumers_1 = document.querySelectorAll('.js-onboard-consumer');
        var _loop_3 = function (i_13) {
            var baseUrl = onboardConsumers_1[i_13].dataset.url;
            load("".concat(baseUrl, "?cols=").concat(onboardConsumers_1[i_13].dataset.cols, "&headerLevel=").concat(onboardConsumers_1[i_13].dataset.headerLevel, "&reload=true"), function (xhr) {
                if (xhr.response.trim()) {
                    var onboardProvider = document.createElement('div');
                    onboardProvider.innerHTML = xhr.response;
                    var onboardTileFirst = onboardProvider.querySelector('.js-onboard-provider li:nth-child(1)');
                    var onboardTileSecond = onboardProvider.querySelector('.js-onboard-provider li:nth-child(2)');
                    onboardConsumers_1[i_13].replaceChild(onboardTileFirst, onboardConsumers_1[i_13].children[0]);
                    onboardConsumers_1[i_13].replaceChild(onboardTileSecond, onboardConsumers_1[i_13].children[1]);
                }
            }, function (err) { });
        };
        for (var i_13 = 0; i_13 < onboardConsumers_1.length; i_13++) {
            _loop_3(i_13);
        }
    }
    // Code Splitting / Lazy loading JS Ressourcen
    if (document.querySelector('.js-reiseportal')
        || document.querySelector('.js-italia')
        || document.querySelector('.js-val')
        || document.querySelector('.js-postbus')
        || document.querySelector('.js-rail-and-drive-locations-map-module')) {
        loadWebComponents();
    }
    if (document.querySelector('.js-rail-tours')) {
        (0, utils_1.loadScript)((0, utils_1.getResourceUrl)('js/matomo-rail-tours.js'));
        loadWebComponents();
    }
    if (document.querySelector('.js-railtours-gutscheine')) {
        (0, utils_1.loadScript)((0, utils_1.getResourceUrl)('js/incert.js'));
    }
    if (document.querySelector('.js-kombitickets')) {
        (0, utils_1.loadScript)((0, utils_1.getResourceUrl)('js/matomo-kombitickets.js'));
        loadWebComponents();
    }
    if (document.querySelector('.js-map-discover')) {
        (0, utils_1.loadStyle)((0, utils_1.getResourceUrl)('css/site-partial-discover.min.css'));
        (0, utils_1.loadScript)((0, utils_1.getResourceUrl)('js/main-discover.min.js'));
    }
    if (document.querySelector('.js-feedback')) {
        (0, utils_1.loadScript)((0, utils_1.getResourceUrl)('js/userback/widget.js'));
    }
    if (document.querySelector('.js-poi-map')) {
        (0, utils_1.loadScript)((0, utils_1.getResourceUrl)('js/main-poi-maps.min.js'));
    }
    if (document.querySelector('.js-chat-wc-start')) {
        (0, utils_1.loadScript)((0, utils_1.getResourceUrl)('js/main-chat-wc.min.js'));
    }
});
function load(url, callback, callbackError) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', encodeURI(url), true);
    xhr.onerror = function (ev) {
        callbackError(ev);
    };
    xhr.onreadystatechange = ensureReadiness;
    function ensureReadiness() {
        if (xhr.readyState < 4) {
            return;
        }
        if (xhr.status !== 200) {
            return;
        }
        // all is well
        if (xhr.readyState === 4) {
            callback(xhr);
        }
    }
    xhr.send(null);
}
exports.load = load;
function loadSynchronous(url, callback) {
    var returnValue = [];
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, false);
    xhr.send(null);
    if (xhr.status === 200) {
        // console.log(xhr.responseText);
        returnValue = callback(xhr.responseText);
    }
    else {
        returnValue = [];
    }
    return returnValue;
}
exports.loadSynchronous = loadSynchronous;
function getScrollTop() {
    return (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
}
exports.default = getScrollTop;
function retrieveCurrentLanguage() {
    var currentLang = document.querySelector("html").getAttribute("lang");
    if (!currentLang) {
        currentLang = "de";
    }
    return currentLang;
}
function loadClosestPolyfill() {
    if (!Element.prototype.matches) {
        Element.prototype.matches =
            Element.prototype.msMatchesSelector ||
                Element.prototype.webkitMatchesSelector;
    }
    if (!Element.prototype.closest) {
        Element.prototype.closest = function (s) {
            var el = this;
            do {
                if (Element.prototype.matches.call(el, s))
                    return el;
                el = el.parentElement || el.parentNode;
            } while (el !== null && el.nodeType === 1);
            return null;
        };
    }
}
